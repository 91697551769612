import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Flex } from "theme-ui";

import { MenuIconSVG } from "@/assets/icons";

export const TableActionsButtons = ({ actions }: { actions: Array<JSX.Element> }) => {
  const { t } = useTranslation();

  const [showMenu, setShowMenu] = useState(false);
  const ref = useRef<HTMLElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const handleOutsideClick = (e: MouseEvent) => {
    if (e.target && !ref.current?.contains(e.target as Node) && !buttonRef.current?.contains(e.target as Node)) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    window.addEventListener("mousedown", handleOutsideClick);
    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  return (
    <Flex sx={{ position: "relative" }}>
      <Button
        ref={buttonRef}
        variant="text"
        aria-label={t("Open Action Menu")}
        onMouseDown={(e) => {
          e.preventDefault();
        }}
        onClick={(e) => {
          e.preventDefault();
          setShowMenu((state) => !state);
        }}
      >
        <MenuIconSVG width={15} height={15} />
      </Button>
      {showMenu && (
        <Flex
          ref={ref}
          sx={{
            height: "45px",
            position: "absolute",
            zIndex: 2,
            right: "110%",
            top: -3,
            background: "primary.600",
            flexDirection: "row",
            alignItems: "center",
            gap: 2,
            borderRadius: "25px",
            width: "max-content",
            px: 2,
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            transition: "all 0.4s ease-in-out",
          }}
        >
          {actions.map((action, i) => (
            <Box key={action.key ?? i}>{action}</Box>
          ))}
        </Flex>
      )}
    </Flex>
  );
};
