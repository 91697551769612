import { useOktaAuth } from "@okta/okta-react";
import { lazy } from "react";
import { Navigate } from "react-router-dom";

import { LoadingOverlayIndicator } from "@/app/components/loading-overlay-indicator";
import { UnverifiedAccountModal } from "@/app/components/unverified-account-modal";
import { FeatureToggleKey, useRecOptions } from "@/app/hooks";
import { useHasPermissions } from "@/app/hooks/use-has-permission/use-has-permission";
import { flattenRoutes } from "@/app/lib/flatten-routes";
import { UserRoles } from "@/app/lib/role-based-access-control";
import { ReceivedCounterOffers } from "@/app/pages/counter-offers";
import { MyCounterOffers, MyCounterOffersList } from "@/app/pages/counter-offers/my-counter-offers";
import { ReceivedCounterOffersList } from "@/app/pages/counter-offers/received-counter-offers/received-counter-offers-list";
import { Markets } from "@/app/pages/markets";
import { OrderHistory } from "@/app/pages/orders";
import { ExportRecs } from "@/app/pages/recs/export";
import ExportHistory from "@/app/pages/recs/export-history/export-history";
import { RecHoldings } from "@/app/pages/recs/rec-holdings";
import { Retire } from "@/app/pages/recs/retire";
import { RetirementHistory } from "@/app/pages/recs/retirement-history/retirement-history";
import { Settings } from "@/app/pages/settings";
import { UserResources } from "@/app/pages/user-resources";
import { AccountStatus, AccountType } from "@/app/types/generated/graphql";

import { PrivateRouteProps, PrivateRoutes } from "./routes.type";

const AdminDashboard = lazy(() => import("@/app/pages/admin-dashboard/admin-dashboard"));
const SellOrder = lazy(() => import("@/app/pages/sell-order/sell-order"));

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ guard, children }) => {
  const { authState } = useOktaAuth();

  const isAuthenticated = authState?.isAuthenticated;

  useRecOptions({ skipQuery: !isAuthenticated });

  const { isActorOnboarding, isActorVerified, hasPermissions, hasRolePermissions, loading } = useHasPermissions({
    skipQuery: !isAuthenticated,
  });

  if (!authState || loading) return <LoadingOverlayIndicator />;

  if (!isAuthenticated) return <Navigate to="/" replace />;

  //If has full permission
  if (hasPermissions(guard)) {
    return children;
  }

  //If Account Status is Onboarding then redirect to Signup
  if (isActorOnboarding) {
    return <Navigate to="/" replace />;
  }

  //If Account status is not onboarding but user going to signup, then redirect to market
  if (guard.allowedStatuses?.includes(AccountStatus.Onboarding)) {
    return <Navigate to="/market" replace />;
  }

  // If Account Status is not Active & also not onboarding, then show unverified modal
  if (!isActorVerified) {
    return <UnverifiedAccountModal visible />;
  }

  //If Account status is active and not onboarding, but grant access fails, then redirect to market
  if (!hasRolePermissions(guard.allowedRoles ?? [])) {
    return <Navigate to="/market" replace />;
  }

  return <Navigate to="/" replace />;
};

/**
 * Private Routes added in order of which it is shown in Layout Sidebar
 * To Show Routes in Sider, add layout property with following type
    navTitle: string;
    subTitle?: string;
    icon: string;
 */
export const privateRoutes: PrivateRoutes[] = [
  {
    path: "/market",
    layout: {
      navTitle: "Market",
      icon: "Markets",
    },
    render: () => <Markets />,
    guard: {
      allowedStatuses: [AccountStatus.Active],
    },
  },
  {
    path: "/recs",
    layout: {
      navTitle: "RECs",
      icon: "HorizontalStack",
    },
    guard: {
      allowedStatuses: [AccountStatus.Active],
    },
    render: () => <></>,
    subRoutes: [
      {
        path: "/managed-recs",
        layout: {
          navTitle: "Managed RECs",
          icon: "RecHoldings",
        },
        guard: {
          allowedStatuses: [AccountStatus.Active],
          allowedAccountTypes: [AccountType.NonRegistry],
        },
        render: () => <RecHoldings />,
        subRoutes: [
          {
            path: "/retire",
            guard: {
              allowedStatuses: [AccountStatus.Active],
              allowedAccountTypes: [AccountType.NonRegistry],
            },
            render: () => <Retire />,
          },
        ],
      },
      {
        path: "/retirement-history",
        layout: {
          navTitle: "Retirement History",
          icon: "Log",
        },
        guard: {
          allowedStatuses: [AccountStatus.Active],
          allowedAccountTypes: [AccountType.NonRegistry],
        },
        render: () => <RetirementHistory />,
      },
      {
        path: "/holdings",
        layout: {
          navTitle: "REC Holdings",
          icon: "RecHoldings",
        },
        guard: {
          allowedStatuses: [AccountStatus.Active],
          allowedAccountTypes: [AccountType.Registry],
        },
        render: () => <RecHoldings />,
        subRoutes: [
          {
            path: "/sell",
            guard: {
              allowedStatuses: [AccountStatus.Active],
              allowedAccountTypes: [AccountType.Registry],
            },
            render: () => <SellOrder />,
          },
          {
            path: "/export",
            guard: {
              allowedStatuses: [AccountStatus.Active],
              allowedAccountTypes: [AccountType.Registry],
            },
            render: () => <ExportRecs />,
          },
        ],
      },
      {
        path: "/export-history",
        layout: {
          navTitle: "Export History",
          icon: "Log",
        },
        guard: {
          allowedStatuses: [AccountStatus.Active],
          allowedAccountTypes: [AccountType.Registry],
          requiredFeatureToggles: [FeatureToggleKey.EnableAutomatedUnencumberance],
        },
        render: () => <ExportHistory />,
      },
    ],
  },
  {
    path: "/counter",
    layout: {
      navTitle: "Counter Offers",
      subTitle: "Counter",
      icon: "CounterOffer",
    },
    guard: {
      allowedStatuses: [AccountStatus.Active],
      requiredFeatureToggles: [FeatureToggleKey.EnableCounterOffer],
      allowedAccountTypes: [AccountType.Registry],
    },
    render: () => <></>,
    subRoutes: [
      {
        path: "/received-offers",
        layout: {
          navTitle: "Received offers",
          icon: "IncomingOffer",
        },
        guard: {
          allowedStatuses: [AccountStatus.Active],
          requiredFeatureToggles: [FeatureToggleKey.EnableCounterOffer],
          allowedAccountTypes: [AccountType.Registry],
        },
        render: () => <ReceivedCounterOffers />,
        subRoutes: [
          {
            path: "/offers",
            guard: {
              allowedStatuses: [AccountStatus.Active],
              requiredFeatureToggles: [FeatureToggleKey.EnableCounterOffer],
              allowedAccountTypes: [AccountType.Registry],
            },
            render: () => <ReceivedCounterOffersList />,
          },
        ],
      },
      {
        path: "/my-offers",
        layout: {
          navTitle: "My Offers",
          icon: "OutgoingOffer",
        },
        guard: {
          allowedStatuses: [AccountStatus.Active],
          requiredFeatureToggles: [FeatureToggleKey.EnableCounterOffer],
          allowedAccountTypes: [AccountType.Registry],
        },
        render: () => <MyCounterOffers />,
        subRoutes: [
          {
            path: "/offers",
            guard: {
              allowedStatuses: [AccountStatus.Active],
              requiredFeatureToggles: [FeatureToggleKey.EnableCounterOffer],
              allowedAccountTypes: [AccountType.Registry],
            },
            render: () => <MyCounterOffersList />,
          },
        ],
      },
    ],
  },

  {
    path: "/orders",
    layout: {
      navTitle: "Order History",
      subTitle: "Trade",
      icon: "OrderHistory",
    },
    guard: {
      allowedStatuses: [AccountStatus.Active],
    },
    render: () => <OrderHistory />,
  },
  {
    path: "/admin",
    guard: {
      allowedStatuses: [AccountStatus.Active],
      allowedRoles: [UserRoles["RoleAdmin"]],
    },
    layout: {
      navTitle: "Admin Dashboard",
      icon: "AdminDashboard",
    },
    render: () => <AdminDashboard />,
  },
  {
    path: "/user-resources",
    layout: {
      navTitle: "User Resources",
      icon: "UserResources",
    },
    render: () => <UserResources />,
    guard: {
      allowedStatuses: [
        AccountStatus.RetryReviewing,
        AccountStatus.AwaitingVerification,
        AccountStatus.AwaitingReverification,
        AccountStatus.Active,
      ],
    },
  },
  {
    path: "/settings",
    render: () => <Settings />,
    guard: {
      allowedStatuses: [
        AccountStatus.RetryReviewing,
        AccountStatus.AwaitingVerification,
        AccountStatus.AwaitingReverification,
        AccountStatus.Active,
      ],
    },
  },
];

export const flattenPrivateRoutes = flattenRoutes(privateRoutes);
