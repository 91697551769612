import {
  Box,
  Button,
  Card,
  Flex,
  IconLoading,
  LegacyCheckbox,
  LegacySwitch,
  Table,
  Text,
  Tooltip,
} from "@powerledger/ui-component-lib";
import currency from "currency.js";
import { format } from "date-fns";
import { startCase, toLower } from "lodash";
import React, { FC, Suspense, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CellProps, Column, Row } from "react-table";

import { EligibilityValueFormatter } from "@/app/components/eligibility-value-formatter";
import { ErrorBoundary } from "@/app/components/error-boundary";
import { OrderCancelModal } from "@/app/components/order-cancel-modal";
import { PageHeader } from "@/app/components/page-header/page-header";
import { RefreshIconButton } from "@/app/components/refresh-icon-button";
import { TableValueWrapper } from "@/app/components/table-value-wrapper";
import { AppDateFormats, formatDate } from "@/app/lib/format-date";

import { CertificationAndEliglibilityType } from "../../lib/format-attributes";
import { getFuelSourceIcon } from "../../lib/get-fuel-source-icon";
import { getTableTranslation } from "../../lib/get-translations-for-components";
import { OrderPosition } from "../../types/generated/graphql";
import { OrderHistoryProps, OrderHistoryTableData } from "./order-history.types";

const TransactionSummaryDownloadButton = React.lazy(
  () =>
    import(
      "../../components/download-report-components/transaction-summary-download-button/transaction-summary-download-button"
    ),
);

export const OrderHistoryView: FC<OrderHistoryProps> = ({
  tableData,
  loading,
  reloadOrderHistory,
  cancelOrderId,
  getOrderIsCancellable,
  initOrderCancellation,
  showOrderCancelModal,
  setShowOrderCancelModal,
  getCanDownloadTransactionSummary,
  handleFilterChange,
  typeFilter,
  pageInfo,
  fetchData,
  typeFilterChanged,
  recOptions,
  showAllAttributes,
  setShowAllAttributes,
}) => {
  const { t } = useTranslation();

  const tableColumns = useMemo(() => {
    const columns: Column<OrderHistoryTableData>[] = [
      {
        Header: t("Order Number"),
        accessor: "orderNumber",
        disableSortBy: true,
        Cell: ({ value }) => <TableValueWrapper value={value} containerSx={{ minWidth: 90 }} />,
      },
      {
        Header: t("Vintage"),
        disableSortBy: true,
        accessor: (row) => row.vintages,
        Cell: ({ value }: { value: string }) => {
          return <TableValueWrapper value={value} csv title="Vintages" containerSx={{ minWidth: 80 }} />;
        },
      },
      {
        Header: t("Eligibility"),
        accessor: "certificationsAndEligibilities",
        disableSortBy: true,
        Cell: ({ value, row }: { row: Row<OrderHistoryTableData>; value: CertificationAndEliglibilityType[] }) => {
          return <EligibilityValueFormatter certificationsAndEligibilities={value} position={row.original.position} />;
        },
      },
      {
        Header: t("Project"),
        accessor: "projects",
        disableSortBy: true,
        Cell: ({ value }: { value: string | string[] }) => {
          const projectValue = value || value?.length ? value : typeFilter === OrderPosition.Bid ? "Any" : "-";
          return <TableValueWrapper value={projectValue} csv title={"Projects"} containerSx={{ minWidth: 100 }} />;
        },
      },

      {
        Header: t("Fuel Type"),
        accessor: (row) => row.attributes.fuelSources,
        Cell: ({ value }: { value: string[] }) => {
          return (
            <Flex sx={{ color: "textDark" }}>
              {!value || value.length < 1 ? (
                typeFilter === OrderPosition.Bid ? (
                  "Any"
                ) : (
                  "-"
                )
              ) : (
                <TableValueWrapper
                  title={"Fuel Sources"}
                  getIcon={getFuelSourceIcon}
                  value={value}
                  containerSx={{ minWidth: 110 }}
                  getToolTipText={(key: string) =>
                    recOptions?.fuelSourceOptions?.filter((x) => x.value === key)[0]?.label || ""
                  }
                />
              )}
            </Flex>
          );
        },
        disableSortBy: true,
      },
      {
        Header: t("Commencement Date (COD)"),
        id: "commencementDate",
        Cell: ({ row }: { row: Row<OrderHistoryTableData> }) => {
          const commencementDate = row.original.attributes?.commencementDate;
          const displayValue = commencementDate ? format(new Date(commencementDate), "P") : "N/A";
          return <TableValueWrapper value={displayValue} />;
        },
        disableSortBy: true,
      },
      {
        Header: t("Status"),
        accessor: "status",
        Cell: ({ value, row: { original } }) => (
          <Flex sx={{ color: "textDark", alignItems: "center" }}>
            {`${t(startCase(toLower(value)))}`}
            {original.statusMessage && (
              <Tooltip
                id={`order-${original.id}-status-tooltip`}
                content={<Text>{t(original.statusMessage)}</Text>}
                sx={{
                  svg: {
                    width: 16,
                    height: 16,
                    path: {
                      fill: "primary.500",
                    },
                  },
                }}
              />
            )}
          </Flex>
        ),
      },
      {
        Header: (
          <Box
            sx={{
              margin: "auto",
            }}
          >
            {t("Total")}
          </Box>
        ),
        id: "total",
        accessor: (row) =>
          currency(row.price?.amount || 0, { fromCents: true })
            .multiply(row.initialVolume)
            .format(),
        width: "100px",
        Cell: ({ value }: { value: string }) => (
          <Box
            sx={{
              textAlign: "right",
              width: "100px",
              px: 2,
            }}
          >
            {value}
          </Box>
        ),
      },
      {
        Header: t("Sale Timestamp"),
        accessor: "completedOn",
        disableSortBy: true,
        Cell: ({ value }) => (
          <TableValueWrapper value={value ? format(new Date(value), "p") + " " + format(new Date(value), "P") : "-"} />
        ),
      },
      {
        Header: t("Expiry Date"),
        accessor: "expiryDate",
        disableSortBy: true,
        Cell: ({ value }) => (
          <TableValueWrapper value={formatDate(value, { formatStyle: AppDateFormats.UniversalDateTimeFormat })} />
        ),
      },
      {
        Header: (
          <Box
            sx={{
              margin: "auto",
            }}
          >
            {t("Unit Price")}
          </Box>
        ),
        id: "unitPrice",

        disableSortBy: true,
        width: "100px",
        Cell: ({ row }: { row: Row<OrderHistoryTableData> }) => (
          <Box
            sx={{
              textAlign: "right",
              width: "100px",
              px: 2,
            }}
          >
            {currency(row.original?.price?.amount || 0, { fromCents: true }).format()}
          </Box>
        ),
      },
      {
        Header: t("Fulfilled Volume"),
        id: "fulfilledVolume",
        disableSortBy: true,
        Cell: ({ row }: { row: Row<OrderHistoryTableData> }) => (
          <TableValueWrapper
            value={currency(
              row.original.initialVolume - (row.original.currentVolume + (row.original.cancelledVolume ?? 0)),
              {
                symbol: "",
                separator: ",",
                precision: 0,
              },
            ).format()}
          />
        ),
      },
      {
        Header: t("Withdrawn Volume"),
        accessor: "cancelledVolume",
        disableSortBy: true,
        Cell: ({ value }) => (
          <TableValueWrapper
            value={currency(value ?? 0, {
              symbol: "",
              separator: ",",
              precision: 0,
            }).format()}
          />
        ),
      },
      {
        Header: t("Ordered Volume"),
        accessor: "initialVolume",
        disableSortBy: true,
        Cell: ({ value }) => (
          <TableValueWrapper
            value={currency(value ?? 0, {
              symbol: "",
              separator: ",",
              precision: 0,
            }).format()}
          />
        ),
      },
      {
        Header: t("Order Type"),
        accessor: "type",
        disableSortBy: true,
        Cell: ({ value }) => <TableValueWrapper value={value} />,
      },
      {
        Header: t("Location"),
        accessor: "locations",
        disableSortBy: true,
        Cell: ({ value }) => (
          <TableValueWrapper value={value} csv title={"Locations"} containerSx={{ minWidth: 110 }} />
        ),
      },
    ];
    columns.push({
      Header: t("Action"),
      id: "action",
      Cell: ({ row: { original } }: CellProps<OrderHistoryTableData>) => (
        <Flex sx={{ justifyContent: "center", alignItems: "center", width: 110 }}>
          {getOrderIsCancellable(original) && (
            <Button
              variant="pill.compactSecondary"
              sx={{ py: 1, mr: 2 }}
              onClick={() => initOrderCancellation(original.id)}
            >
              {t("Withdraw")}
            </Button>
          )}
          {getCanDownloadTransactionSummary(original) && (
            <ErrorBoundary fallback={<div>{t("Something went wrong")}</div>}>
              <Suspense fallback={<IconLoading sx={{ ml: 2 }} size="small" />}>
                <TransactionSummaryDownloadButton order={original} />
              </Suspense>
            </ErrorBoundary>
          )}
        </Flex>
      ),
    });
    return columns;
  }, [
    t,
    typeFilter,
    initOrderCancellation,
    getCanDownloadTransactionSummary,
    getOrderIsCancellable,
    recOptions?.fuelSourceOptions,
  ]);

  return (
    <Box as="main" sx={{ m: 4 }}>
      <PageHeader
        title={t("Order History")}
        filterProps={{
          applyFilters: handleFilterChange,
          shouldSelectVintage: false,
          hideStatusFilter: false,
        }}
      />

      <Flex sx={{ alignItems: "center", mb: 2 }}>
        <Flex sx={{ ml: "auto" }}>
          <RefreshIconButton loading={loading} fetchData={reloadOrderHistory} />
        </Flex>
      </Flex>

      <Card variant="compact">
        <Flex
          sx={{
            background: "lightActive",
            p: 3,
            pb: 3,
            mb: -1,
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
            justifyContent: "space-between",
            borderWidth: "0.5px",
            borderStyle: "solid",
            borderColor: "shadow",
            zIndex: 1,
            position: "relative",
            borderBottomWidth: 0,
          }}
        >
          <LegacyCheckbox
            checked={showAllAttributes}
            sx={{
              color: "textDarker",
              fontWeight: "bold",
            }}
            label={t("Show All Attributes")}
            onChange={() => setShowAllAttributes((prev) => !prev)}
          />
          <LegacySwitch
            checked={typeFilter === OrderPosition.Ask}
            uncheckedLabel={t("Bought")}
            checkedLabel={t("Sold")}
            onChange={typeFilterChanged}
          />
        </Flex>
        <Box
          sx={{
            table: {
              td: {
                whiteSpace: "unset",
              },
            },
          }}
        >
          <Table
            columns={tableColumns}
            dataSource={tableData}
            pageInfo={pageInfo}
            initialState={
              showAllAttributes
                ? {}
                : {
                    hiddenColumns: [
                      "type",
                      "orderedVolume",
                      "initialVolume",
                      "cancelledVolume",
                      "fulfilledVolume",
                      "unitPrice",
                      "expiryDate",
                      "completedOn",
                      "locations",
                      "commencementDate",
                    ],
                  }
            }
            fetchData={fetchData}
            manualPagination
            loading={loading}
            showScrollArrows
            translation={getTableTranslation(t)}
            containerSx={{
              table: {
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                borderTop: 0,
              },
            }}
          />
        </Box>
      </Card>
      <OrderCancelModal
        visible={showOrderCancelModal}
        orderId={cancelOrderId}
        closeModal={() => setShowOrderCancelModal(false)}
      />
    </Box>
  );
};
