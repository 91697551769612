import { Tabs } from "@powerledger/ui-component-lib";
import { useTranslation } from "react-i18next";
import { Box, Card } from "theme-ui";

import { HeaderText } from "@/app/components/page-header";
import { MessageStatus } from "@/app/types/generated/graphql";

import { NotificationViewProps } from "./notifications.types";
import { NotificationList } from "./notifications-list";

export const NotificationView: React.FC<NotificationViewProps> = ({
  currentTab,
  setCurrentTab,
  refreshNotificationBadge,
}) => {
  const { t } = useTranslation();
  return (
    <Box as="main" sx={{ m: 4 }}>
      <HeaderText title={t("Notifications")} />
      <Box mt="4">
        <Card variant="compact" sx={{ position: "relative", minHeight: 200 }}>
          <Tabs activeKey={currentTab} onChange={setCurrentTab}>
            <Tabs.TabPane tab={t(MessageStatus.Unread)} key={MessageStatus.Unread}>
              <NotificationList status={MessageStatus.Unread} onMarkAsRead={refreshNotificationBadge} />
            </Tabs.TabPane>
            <Tabs.TabPane tab={t(MessageStatus.Read)} key={MessageStatus.Read}>
              <NotificationList status={MessageStatus.Read} />
            </Tabs.TabPane>
          </Tabs>
        </Card>
      </Box>
    </Box>
  );
};
