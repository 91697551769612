import { Box, Card, Flex, LegacyCheckbox, Table } from "@powerledger/ui-component-lib";
import { useTranslation } from "react-i18next";

import { HeaderText } from "@/app/components/page-header/header-text";
import { RefreshIconButton } from "@/app/components/refresh-icon-button";
import { UserMessage } from "@/app/components/user-message/user-message";
import { getTableTranslation } from "@/app/lib/get-translations-for-components";

import { CounteredOrdersProps } from "./countered-orders.types";

export const CounteredOrdersView = ({
  showAllAttributes,
  setShowAllAttributes,
  tableData,
  titles,
  userMessage,
  loading,
  tableColumns,
  reloadCounteredOrders,
  fetchData,
  pageInfo,
}: CounteredOrdersProps) => {
  const { t } = useTranslation();

  return (
    <Flex as="main" sx={{ m: 4, flexDirection: "column", gap: 2 }}>
      <HeaderText title={titles} sx={{ width: "fit-content" }} />
      <UserMessage message={userMessage} />

      <Card variant="compact" sx={{ mt: 4 }}>
        <Flex
          sx={{
            background: "lightActive",
            p: 3,
            pb: 3,
            mb: -1,
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
            justifyContent: "space-between",
            borderWidth: "0.5px",
            borderStyle: "solid",
            borderColor: "shadow",
            zIndex: 1,
            position: "relative",
            borderBottomWidth: 0,
            alignItems: "center",
          }}
        >
          <LegacyCheckbox
            checked={showAllAttributes}
            sx={{
              color: "textDarker",
            }}
            label={t("Show All Attributes")}
            onChange={() => setShowAllAttributes((prev) => !prev)}
          />
          <RefreshIconButton loading={false} fetchData={reloadCounteredOrders} />
        </Flex>
        <Box
          sx={{
            table: {
              td: {
                whiteSpace: "unset",
              },
            },
          }}
        >
          <Table
            loading={loading}
            columns={tableColumns}
            dataSource={tableData}
            initialState={
              showAllAttributes
                ? {}
                : {
                    hiddenColumns: ["commencementDate", "locations", "projects"],
                  }
            }
            fetchData={fetchData}
            pageInfo={pageInfo}
            manualPagination
            showScrollArrows
            translation={getTableTranslation(t)}
            containerSx={{
              table: {
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                borderTop: 0,
              },
            }}
          />
        </Box>
      </Card>
    </Flex>
  );
};
