import { PageInfo, TableFetchData } from "@powerledger/ui-component-lib";

import { RECOrderAttributeOptions } from "@/app/lib/format-rec-options";

import { ApplyFiltersFn, FilterState } from "../../components/page-header/filter/filter.types";
import { ReturnTypeFormatAttributes } from "../../lib/format-attributes";
import {
  AssetType,
  FewAccountDetailsQuery,
  Order,
  OrderPosition,
  OrderStatus,
  OrderType,
  RecOrderAttributes,
} from "../../types/generated/graphql";

export type TypeFilter = Record<OrderPosition, boolean>;

export const defaultTypeFilterState = {
  [OrderPosition.Ask]: true,
  [OrderPosition.Bid]: true,
};
export type AssetCount = {
  bid: number;
  ask: number;
};

export type MarketViewProps = {
  typeFilter: OrderPosition | null;
  handleChangeTypeFilter: (value: OrderPosition | null) => void;
  recOptions?: RECOrderAttributeOptions;
  assetCounts?: AssetCount;
  hideSellButton?: boolean;
  reloadMarketOrders: () => void;
  pageInfo: PageInfo;
  fetchData: TableFetchData<MarketTableData>;
  tableData: MarketTableData[];
  hideBuyButton?: boolean;
  applyFilters: ApplyFiltersFn;
  loading?: boolean;
  selectedFilters: FilterState;
  showAllAttributes: boolean;
  setShowAllAttributes: React.Dispatch<React.SetStateAction<boolean>>;
  accountData?: FewAccountDetailsQuery;
};

export type TableOrderType = {
  id: string;
  assetType: AssetType;
  position: OrderPosition;
  type: OrderType;
  status: OrderStatus;
  initialVolume: number;
  currentVolume: number;
  unitPrice: string;
  vintage: number;
  eligibilities: string[];
  locations: string[];
  fuelSources: string[];
};
export type MarketTableData = Omit<Order, "attributes"> & {
  attributes: RecOrderAttributes;
} & ReturnTypeFormatAttributes;
