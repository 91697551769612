import { useTablePaginationManager } from "@powerledger/ui-component-lib";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useRecOptions } from "@/app/hooks/use-rec-options";
import { formatAttributes, ReturnTypeFormatAttributes } from "@/app/lib/format-attributes";
import {
  CounterOrderDirection,
  RecOrderAttributes,
  SortOrderInput,
  useCounteredOrdersQuery,
} from "@/app/types/generated/graphql";

import { CounteredOrdersData, OffersPageDesc, OffersTitle, RoutePaths } from "./countered-orders.types";
import { getCounteredOrdersColumn } from "./countered-orders-column";

export const useCounteredOrders = (counterOrderDirection: CounterOrderDirection) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showAllAttributes, setShowAllAttributes] = useState(false);
  const titles = OffersTitle[counterOrderDirection];
  const userMessage = OffersPageDesc[counterOrderDirection];

  const { pageInfo, offset, fetchData, sort, setTotalItems } = useTablePaginationManager<
    CounteredOrdersData,
    SortOrderInput[]
  >({});

  const { data, loading, previousData, refetch } = useCounteredOrdersQuery({
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        direction: counterOrderDirection,
        paginationInput: {
          offset,
          limit: pageInfo.pageSize,
        },
        sortOrderInputs: sort,
      },
    },
    onCompleted(data) {
      setTotalItems(data?.counteredOrders.offsetInfo.total ?? 0);
    },
  });
  const currentData = useMemo(() => data ?? previousData, [data, previousData]);

  const { recOptions, loading: recOptionsLoading } = useRecOptions();

  const tableData = useMemo(() => {
    const counteredOrdersData: CounteredOrdersData[] = [];

    if (!currentData?.counteredOrders.orders) return counteredOrdersData;
    for (const order of currentData.counteredOrders.orders) {
      const attributes = order.attributes as RecOrderAttributes;
      const formattedAttributes = recOptions
        ? formatAttributes({
            attributes,
            position: order.position,
            options: recOptions,
          })
        : ({} as ReturnTypeFormatAttributes);
      counteredOrdersData.push({
        ...order,
        ...formattedAttributes,
        attributes,
      });
    }
    return counteredOrdersData;
  }, [currentData?.counteredOrders.orders, recOptions]);

  const reloadCounteredOrders = useCallback(() => {
    refetch();
  }, [refetch]);

  const onSeeCounterOffers = useCallback(
    (recs: CounteredOrdersData) => {
      navigate(RoutePaths[counterOrderDirection], { state: { selectedRecs: recs, counterOrderDirection } });
    },
    [navigate, counterOrderDirection],
  );

  const tableColumns = useMemo(
    () => getCounteredOrdersColumn(t, onSeeCounterOffers, counterOrderDirection, recOptions),
    [t, onSeeCounterOffers, recOptions, counterOrderDirection],
  );

  return {
    showAllAttributes,
    setShowAllAttributes,
    recOptions,
    tableData: tableData,
    loading: loading || recOptionsLoading,
    reloadCounteredOrders,
    fetchData,
    pageInfo,
    titles,
    userMessage,
    tableColumns,
    onSeeCounterOffers,
  };
};
