import { PageInfo, TableFetchData } from "@powerledger/ui-component-lib";
import { Column } from "react-table";

import { ReturnTypeFormatAttributes } from "@/app/lib/format-attributes";
import { RECOrderAttributeOptions } from "@/app/lib/format-rec-options";
import { Order, RecOrderAttributes } from "@/app/types/generated/graphql";

export type CounteredOrdersData = Omit<Order, "attributes"> & {
  attributes: RecOrderAttributes;
} & ReturnTypeFormatAttributes;

export type CounteredOrdersProps = {
  showAllAttributes: boolean;
  setShowAllAttributes: React.Dispatch<React.SetStateAction<boolean>>;
  recOptions?: RECOrderAttributeOptions;
  tableData: CounteredOrdersData[];
  titles: string;
  userMessage: string;
  loading: boolean;
  onSeeCounterOffers: (recs: CounteredOrdersData) => void;
  tableColumns: Column<CounteredOrdersData>[];
  reloadCounteredOrders: () => void;
  fetchData: TableFetchData<CounteredOrdersData>;
  pageInfo: PageInfo;
};

export type TableDataProps = {
  id: string;
  receivedOn: string;
  counterNumber: string;
  status: string;
};

export enum OffersTitle {
  INCOMING = "Received Counter Offers",
  OUTGOING = "My Counter Offers",
}

export enum OffersPageDesc {
  INCOMING = "Here is the list of sell orders that have received counter offers.",
  OUTGOING = "Here is the list of sell orders, you have submitted counter offers for.",
}

export enum RoutePaths {
  INCOMING = "/counter/received-offers/offers",
  OUTGOING = "/counter/my-offers/offers",
}

export enum GoBackPaths {
  INCOMING = "/counter/received-offers",
  OUTGOING = "/counter/my-offers",
}

export enum HeadersTitle {
  INCOMING = "Order Number",
  OUTGOING = "Order Reference",
}
