import { Box, Flex, InfiniteScroll, LoadingOverlay } from "@powerledger/ui-component-lib";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { NotificationsListProps } from "./notifications-list.types";
import { NotificationListView } from "./notifications-list-view";
import { useNotificationList } from "./use-notifications-list";

export const NotificationList: FC<NotificationsListProps> = ({ status, onMarkAsRead }) => {
  const { t } = useTranslation();
  const { allMessages, fetchInboxMessages, loading, hasMoreData, markAsRead, markAsReadId } = useNotificationList({
    status,
    onMarkAsRead,
  });

  if (!loading && !allMessages.length) {
    return (
      <Flex sx={{ alignItems: "center", justifyContent: "center", color: "textDarker", py: 2 }}>{t("No data")}</Flex>
    );
  }

  return (
    <InfiniteScroll hasMoreData={hasMoreData} getMoreData={fetchInboxMessages}>
      <Box
        sx={{
          maxHeight: "418px",
          overflowY: "auto",
          overscrollBehaviorY: "contain",
        }}
      >
        <LoadingOverlay loading={loading} />
        <NotificationListView
          notifications={allMessages}
          markAsRead={markAsRead}
          loading={loading}
          markAsReadId={markAsReadId}
        />
      </Box>
    </InfiniteScroll>
  );
};
