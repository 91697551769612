import { uniqBy } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import notifications from "@/app/container/notifications";
import {
  InboxMessage,
  MarkAsReadMutation,
  MessageStatus,
  OrderByDirection,
  useInboxMessagesQuery,
  useMarkAsReadMutation,
} from "@/app/types/generated/graphql";

import { UseNotificationListFn } from "./notifications-list.types";

export const PAGE_LIMIT = 20;

export const useNotificationList: UseNotificationListFn = ({ status, onMarkAsRead }) => {
  const [offset, setOffset] = useState(0);
  const [totalMessageCount, setTotalMessageCount] = useState(0);
  const [markAsReadId, setMarkAsReadId] = useState<string>("");
  const { t } = useTranslation();

  const {
    data: inboxMessages,
    refetch,
    loading,
  } = useInboxMessagesQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      input: {
        filters: {
          status,
        },
        paginationInput: {
          limit: PAGE_LIMIT,
          offset: offset * PAGE_LIMIT,
        },
        sortOrderInputs: [
          {
            field: "dateCreated",
            sortOrder: OrderByDirection.Desc,
          },
        ],
      },
    },
    onCompleted: (data) => setTotalMessageCount(data?.messages?.offsetInfo?.total ?? 0),
  });

  const [allMessages, setAllMessages] = useState<InboxMessage[]>(inboxMessages?.messages?.messages ?? []);

  useEffect(() => {
    if (inboxMessages?.messages?.messages) {
      setAllMessages((prev) => {
        if (!offset) {
          return inboxMessages.messages.messages;
        }

        const newMessages = inboxMessages.messages.messages;
        const updatedMessages = uniqBy([...prev, ...newMessages], "id");

        return updatedMessages;
      });
    }
  }, [inboxMessages?.messages?.messages, offset]);

  const fetchInboxMessages = useCallback(() => {
    setOffset((offset) => offset + 1);
  }, []);

  const hasMoreData = useMemo(() => totalMessageCount > allMessages.length, [totalMessageCount, allMessages.length]);

  const handleOnComplete = async (data: MarkAsReadMutation) => {
    // refetch all the currently available unread messages - 1 (because '1' has been marked as read)
    await refetch({
      input: {
        filters: {
          status: MessageStatus.Unread,
        },
        paginationInput: {
          limit: allMessages.length - 1 > 0 ? allMessages.length - 1 : PAGE_LIMIT,
          offset: 0,
        },
        sortOrderInputs: [
          {
            field: "dateCreated",
            sortOrder: OrderByDirection.Desc,
          },
        ],
      },
    });

    setAllMessages((prevMessages) => prevMessages.filter((message) => message.id !== data.markAsRead?.id));

    onMarkAsRead?.();
  };

  const [markAsRead] = useMarkAsReadMutation({
    async onCompleted(data) {
      await handleOnComplete(data);
    },
  });

  const markAsReadHandler = useCallback(
    async (id: string) => {
      try {
        setMarkAsReadId(id);
        await markAsRead({ variables: { markAsReadId: id } });
      } catch (e) {
        notifications.error({ description: t("Something went wrong") });
      } finally {
        setMarkAsReadId("");
      }
    },
    [markAsRead, t],
  );

  return {
    allMessages,
    fetchInboxMessages,
    hasMoreData,
    loading,
    markAsReadId,
    markAsRead: markAsReadHandler,
    handleOnComplete,
  };
};
