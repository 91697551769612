import { CounteredOrdersData } from "@/app/pages/counter-offers/components/countered-orders";
import { MarketTableData } from "@/app/pages/markets/market.types";
import { OrderType } from "@/app/types/generated/graphql";

export enum CounterSteps {
  COUNTER_FORM,
  COUNTER_SUBMIT,
  COUNTER_SUCCESS,
}

export type CreateCounterOfferModalDefaultProps = {
  visible: boolean;
  closeModal: () => void;
};

export type CreateCounterOfferModalCustomProps = {
  counteredTableData: MarketTableData | CounteredOrdersData;
  tenantID?: string;
  counterFromID?: string;
  offerNumber?: string | null;
  offerGroupNumber?: string;
  counteredVolume?: number;
};

export type CreateCounterOfferHookProps = CreateCounterOfferModalDefaultProps & CreateCounterOfferModalCustomProps;

export type CreateCounterOfferModalProps = CreateCounterOfferHookProps & {
  currentStep: CounterSteps;
  modalSubTitle: string;
  handleStepChange: (step: CounterSteps) => void;
  currencySymbol: string;
  onCounterConfirm: (values: FormValues) => void;
  fee: number;
};

export type QuanityFieldProps = {
  currentStep: CounterSteps;
  field: {
    label: string;
    fieldName: string;
    hasPrefix: boolean;
    fieldType: string;
  };
  currencySymbol: string;
  orderType: OrderType;
};

export type FormValues = {
  counterVolume: number;
  counterPrice: number;
};

export enum CounterFormField {
  counterVolume = "counterVolume",
  counterPrice = "counterPrice",
}
