import { useCallback, useEffect, useState } from "react";

import {
  InboxMessage,
  InboxMessagesQuery,
  MessageStatus,
  useUnreadMessagesCountQuery,
} from "@/app/types/generated/graphql";

import { UseNotificationsFn } from "./notifications.types";

export function filterMessagesByStatus(notifications: InboxMessagesQuery["messages"]["messages"]) {
  const allUnread =
    (notifications?.filter((notification) => notification?.status === MessageStatus.Unread) as InboxMessage[]) ?? [];
  const allRead =
    (notifications?.filter((notification) => notification?.status === MessageStatus.Read) as InboxMessage[]) ?? [];
  return { allUnread, allRead };
}

export const useNotifications: UseNotificationsFn = (args) => {
  const { data: unreadMessages, refetch } = useUnreadMessagesCountQuery({
    fetchPolicy: "network-only",
    variables: {
      input: {
        filters: {
          status: MessageStatus.Unread,
        },
        paginationInput: {
          limit: 10, // max 10, UI to show '9+' for more than 10 unread messages
          offset: 0,
        },
        sortOrderInputs: [], // don't need to sort only to get the unread messages count
      },
    },
  });

  const unreadMessageCount = unreadMessages?.messages.offsetInfo.total ?? 0;
  const [currentTab, setCurrentTab] = useState<MessageStatus>(
    unreadMessageCount ? MessageStatus.Unread : MessageStatus.Read,
  );

  useEffect(() => {
    !args?.showMenu && setCurrentTab(unreadMessageCount ? MessageStatus.Unread : MessageStatus.Read);
  }, [args?.showMenu, unreadMessageCount]);

  const refreshNotificationBadge = useCallback(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    args?.showMenu && refreshNotificationBadge();
  }, [args?.showMenu, refreshNotificationBadge]);

  return {
    currentTab,
    setCurrentTab,
    unreadMessageCount,
    refreshNotificationBadge,
  };
};
