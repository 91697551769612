import { useState } from "react";

import { useOutSideBoundaryClick } from "@/app/hooks/use-outside-boundary-click";

import { useNotifications } from "./notifications/use-notifications";

export const useNotificationBadge = () => {
  const [showMenu, setShowMenu] = useState(false);
  const notifications = useNotifications({ showMenu });

  const { menuRef, buttonRef } = useOutSideBoundaryClick({ setShowMenu });

  return {
    showMenu,
    setShowMenu,
    menuRef,
    buttonRef,
    ...notifications,
  };
};
