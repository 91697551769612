import {
  Box,
  Button,
  Divider,
  Flex,
  IconClipboard,
  IconLinkThin,
  IconLoading,
  LegacyCheckbox,
  Text,
} from "@powerledger/ui-component-lib";
import { startCase } from "lodash";
import { useTranslation } from "react-i18next";

import { LegalDocumentModal } from "@/app/components/legal-check-boxes/LegalDocumentModal";
import { useTranslatedFile } from "@/app/hooks/use-translated-file";
import { SignupStepperStages } from "@/app/lib/account-service-helpers";
import { TRACEX_PRODUCT_HOLDING_TERMS } from "@/app/lib/env-helpers";
import { AccountType } from "@/app/types/generated/graphql";

import { StepButton } from "../step-button";
import { StepLayout } from "../step-layout";
import { AccountSelectionDisclaimer } from "./account-type-disclaimer-view";
import { AccountTypeSelectionViewProps } from "./account-type-selection.types";

const getIcon = (selected = false, disabled = false, iconFor = AccountType.Registry) =>
  iconFor === AccountType.Registry ? (
    <IconLinkThin stroke={selected ? "plain" : "primary.500"} size={120} />
  ) : (
    <IconClipboard
      size={120}
      color={selected ? "primary.50" : disabled ? "shadow" : "primary.500"}
      sx={{
        path: {
          stroke: "transparent !important",
        },
      }}
    />
  );

export const AccountTypeSelectionView: React.FC<AccountTypeSelectionViewProps> = ({
  goForward,
  selected,
  handleAccountTypeSelect,
  canSelect,
  loading,
  checked,
  handleAccountTypeCheck,
  canToggleCheck,
  showLegalDocumentModal,
  setShowLegalDocumentModal,
}) => {
  const { t } = useTranslation();
  const shouldDisableButton = !selected || (selected === AccountType.NonRegistry && !checked);
  const { loading: loadingTranslatedFile, File } = useTranslatedFile("translation", "productHoldingTerms");
  return (
    <StepLayout
      id="accountTypeSelection"
      heading={t(SignupStepperStages.AccountTypeSelection)}
      disclaimer={<AccountSelectionDisclaimer />}
      actions={
        <Flex
          sx={{
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <StepButton disabled={shouldDisableButton} onClick={goForward} />
        </Flex>
      }
    >
      {loading ? (
        <IconLoading />
      ) : (
        <Box>
          <Flex
            sx={{
              gap: 2,
            }}
          >
            <AccountTypeSelectCard
              disabled={!canSelect}
              text={t("{{type}}", {
                type: startCase(AccountType.NonRegistry),
              })}
              getIcon={(selected, disabled) => getIcon(selected, disabled, AccountType.NonRegistry)}
              selected={selected === AccountType.NonRegistry}
              onClick={() => handleAccountTypeSelect(AccountType.NonRegistry)}
            />
            <AccountTypeSelectCard
              disabled={!canSelect}
              onClick={() => handleAccountTypeSelect(AccountType.Registry)}
              text={t("{{type}}", {
                type: startCase(AccountType.Registry),
              })}
              selected={selected === AccountType.Registry}
              getIcon={(selected, disabled) => getIcon(selected, disabled, AccountType.Registry)}
            />
          </Flex>
          {selected === AccountType.NonRegistry && (
            <LegacyCheckbox
              disabled={!canToggleCheck}
              name="nrmTermsAccepted"
              data-testid={"nrmTermsAcceptedCheckbox"}
              label={
                <Text>
                  {t(
                    "By proceeding, you agree, on behalf of the Applicant entity, for the Applicant to be bound by the ",
                  )}
                  <Button
                    variant="text"
                    sx={{
                      fontFamily: "inter",
                      textAlign: "left",
                      fontWeight: "bold",
                      textDecorationLine: "underline",
                      color: "primary.600",
                    }}
                    onClick={() => setShowLegalDocumentModal(true)}
                  >
                    {t("Product Holding Terms")}
                  </Button>
                  {t(
                    ", and acknowledge that the Products will be held pursuant to these terms on the Applicant's behalf for the purposes retirement only (they will not be able to be resold). Tick the box to indicate your acceptance.",
                  )}
                </Text>
              }
              sx={{ mt: 3, fontFamily: "MintGroteskV08", fontSize: 0 }}
              checked={checked}
              onChange={(e) => handleAccountTypeCheck(e?.target.checked)}
            />
          )}

          <LegalDocumentModal
            visible={showLegalDocumentModal}
            content={loadingTranslatedFile ? undefined : File}
            closeModal={() => setShowLegalDocumentModal(false)}
            download={TRACEX_PRODUCT_HOLDING_TERMS}
          />
        </Box>
      )}
    </StepLayout>
  );
};

const AccountTypeSelectCard = ({
  text,
  getIcon,
  onClick,
  selected,
  details,
  disabled,
}: {
  onClick: () => void;
  text: string;
  getIcon: (selected: boolean, disabled: boolean) => React.ReactElement;
  selected: boolean;
  details?: React.ReactElement;
  disabled: boolean;
}) => (
  <Button
    sx={{
      borderRadius: 4,
      boxShadow: `0 0 5px var(--theme-ui-colors-shadow)`,
      width: 300,
      border: "white",
      flexDirection: "column",
      svg: {
        height: "100px",
        top: 0,
      },
      "&:hover, &:focus": disabled
        ? {}
        : {
            bg: "primary.100",
          },
      justifyContent: "flex-start",
      textAlign: "left",
    }}
    onClick={onClick}
    disabled={selected ? false : disabled}
    variant={selected ? "primary" : "secondary"}
  >
    <Flex
      sx={{
        width: "100%",
        px: 3,
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "flex-start",
      }}
    >
      {getIcon(selected, disabled)}
      <Text sx={{ my: 3, fontSize: 1 }}>{text}</Text>
    </Flex>
    {details && (
      <>
        <Divider sx={{ color: "shadow", width: "100%" }} />
        <Flex
          sx={{
            justifyContent: "flex-start",
            mt: 3,
          }}
        >
          {details}
        </Flex>
      </>
    )}
  </Button>
);
