import { Modal, Table } from "@powerledger/ui-component-lib";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Column, Row } from "react-table";

import { getTableTranslation } from "@/app/lib/get-translations-for-components";
import { volumeFormatter } from "@/app/lib/volume-formatter";
import { getCommonRecTableData } from "@/app/pages/recs/common/rec-table-column";
import { RetirementStatus } from "@/app/types/generated/graphql";

import { ActionStatusChip } from "../../action-status-chip/action-status-chip";
import { LoadingOverlayIndicator } from "../../loading-overlay-indicator";
import { TableValueWrapper } from "../../table-value-wrapper";
import { AttributesTooltip, GenericTooltip } from "../../tooltips";
import { RecActionDetailsModalProps, RecActionDetailsTableData } from "./rec-actions-details-modal.types";

export default function RecActionDetailsModal({
  recOptions,
  data,
  loading,
  visible,
  title,
  subtitle,
  closeModal,
  extraContent,
  showStatusMessage = false,
}: Readonly<RecActionDetailsModalProps>) {
  const { t } = useTranslation();
  const columns: Column<RecActionDetailsTableData>[] = useMemo(() => {
    const attributesColumn = [...getCommonRecTableData(t, recOptions)] as Column<RecActionDetailsTableData>[];
    attributesColumn.pop();
    return [
      ...attributesColumn,
      {
        Header: t("Status"),
        accessor: "status",
        disableSortBy: true,
        Cell: ({ row, value }: { row: Row<RecActionDetailsTableData>; value: RetirementStatus }) => {
          return (
            <ActionStatusChip status={value} statusMessage={showStatusMessage ? row.original.statusMessage : ""} />
          );
        },
      },
      {
        Header: t("Quantity"),
        accessor: "volume",
        Cell: ({ value }: { value: number }) => {
          return <TableValueWrapper value={volumeFormatter(value)} />;
        },
        disableSortBy: true,
      },
    ];
  }, [recOptions, showStatusMessage, t]);

  if (loading) {
    return <LoadingOverlayIndicator />;
  }
  return (
    <Modal
      visible={visible}
      sx={{
        height: window.innerHeight < 800 ? `${window.innerHeight - 100}px !important` : "auto",
        overflow: "auto",
        maxHeight: "700px",
        p: 4,
      }}
      onCancel={closeModal}
      title={title}
      subtitle={subtitle}
    >
      <Table
        translation={getTableTranslation(t)}
        pagination={false}
        columns={columns}
        dataSource={data}
        showScrollArrows
      />
      {extraContent}

      <AttributesTooltip />
      <GenericTooltip />
    </Modal>
  );
}
