import { OfferStatus } from "@/app/types/generated/graphql";

export const getChipVariantForCounterOfferStatus = (status: OfferStatus) => {
  const chipVariant: Record<OfferStatus, "primary" | "error" | "blue"> = {
    [OfferStatus.Accepted]: "primary",
    [OfferStatus.Executed]: "primary",
    [OfferStatus.ExecutionFailed]: "error",
    [OfferStatus.Failed]: "error",
    [OfferStatus.Pending]: "blue",
    [OfferStatus.Countered]: "blue",
    [OfferStatus.Requested]: "blue",
    [OfferStatus.Rejected]: "error",
    [OfferStatus.Withdrawn]: "blue",
  };

  return chipVariant[status];
};
