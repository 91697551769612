import notificationExpiredImage from "@/assets/images/2022_03_30_TraceX_Notifications_EXPIRED.png";
import privacyPolicyImage from "@/assets/images/2022_04_05_PowerLedger_Privacy_Policy.png";
import termsImage from "@/assets/images/2022_04_05_TraceX_Platform_Term_of_Access.png";
import rulesImage from "@/assets/images/2022_04_05_TraceX_Rules.png";
import rulesExpiredImage from "@/assets/images/2023_02_14_TraceX_Rules_EXPIRED.png";
import notificationImage from "@/assets/images/2023_02_17_TraceX_Notification.png";
import userGuideImage from "@/assets/images/2024_02_01_TraceX_User_Guide.png";
import faqImage from "@/assets/images/Customer FAQ TraceX_V03.png";

import {
  FAQ_URL,
  NOTIFICATION_URL,
  NOTIFICATION_URL_EXPIRED,
  PRIVACY_POLICY_URL,
  TERMS_OF_ACCESS_URL,
  TRACEX_RULES_URL,
  TRACEX_RULES_URL_EXPIRED,
  USER_GUIDE_URL,
} from "../../lib/env-helpers";
import { ResourceType } from "./user-resources.types";

/**
 * Show Expired Resources in Alphabetical order
 */
export const getExpiredResources = (): ResourceType[] => [
  {
    url: NOTIFICATION_URL_EXPIRED,
    name: "TraceX Notification",
    expired: true,
    image: notificationExpiredImage,
  },
  {
    url: TRACEX_RULES_URL_EXPIRED,
    name: "TraceX Rules",
    expired: true,
    image: rulesExpiredImage,
  },
];

export const getResources = (args: { authenticated?: boolean }): ResourceType[] => {
  // Sorted alphabetically by `name`
  return [
    {
      url: PRIVACY_POLICY_URL,
      name: "Privacy Policy",
      image: privacyPolicyImage,
    },
    {
      url: TERMS_OF_ACCESS_URL,
      name: "Terms of Access",
      image: termsImage,
    },
    {
      url: FAQ_URL,
      name: "TraceX FAQ",
      image: faqImage,
    },
    ...(args.authenticated
      ? [
          {
            url: NOTIFICATION_URL,
            name: "TraceX Notification (M-RETS)",
            image: notificationImage,
          },
        ]
      : []),
    {
      url: TRACEX_RULES_URL,
      name: "TraceX Rules",
      image: rulesImage,
    },
    {
      url: USER_GUIDE_URL,
      name: "User Guide",
      image: userGuideImage,
    },
  ];
};
