import { Flex, IconInfo, Table } from "@powerledger/ui-component-lib";
import currency from "currency.js";
import { startCase } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Column, Row } from "react-table";
import { Button, Text } from "theme-ui";

import { CounterReviewModal } from "@/app/components/counter-review-modal";
import { TableActionsButtons } from "@/app/components/table-actions-buttons";
import { AppDateFormats, formatDate } from "@/app/lib/format-date";
import { getTableTranslation } from "@/app/lib/get-translations-for-components";
import { Offer, OfferAction, OfferStatus } from "@/app/types/generated/graphql";

import { CounterOffersViewProps, OfferActions } from "./counter-offers.types";
import { getChipVariantForCounterOfferStatus } from "./helper";

export const CounterOffersView: React.FC<CounterOffersViewProps> = ({
  offers,
  loading,
  canTakeOfferAction,
  showOfferReviewModal,
  initReviewOffer,
  reviewOfferModalTitle,
  setShowOfferReviewModal,
  onReviewConfirm,
  reviewOfferLoading,
  reviewOfferModalDescription,
  initCreateCounterOffer,
}) => {
  const { t } = useTranslation();

  const columns = useMemo<Column<Offer>[]>(() => {
    return [
      {
        Header: "Counter Number",
        accessor: "offerNumber",
        disableSortBy: true,
      },
      {
        Header: "Created On",
        accessor: "createdOn",
        disableSortBy: true,
        Cell: ({ value }: { value: string }) => (
          <Text>{formatDate(value, { formatStyle: AppDateFormats.AbbreviatedMonthFormatWithTime })}</Text>
        ),
      },
      {
        Header: "Counter Volume",
        accessor: "volume",
        disableSortBy: true,
      },
      {
        Header: "Counter Price",
        accessor: "unitPrice",
        disableSortBy: true,
        Cell: ({ value }: { value: number }) => (
          <Text>
            {currency(value || 0, {
              separator: ",",
              fromCents: true,
            }).format()}
          </Text>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        disableSortBy: true,
        Cell: ({ value, row }: { value: OfferStatus; row: Row<Offer> }) => {
          return (
            <Flex>
              <Button
                as="div"
                sx={{
                  height: 22,
                  display: "flex",
                  px: 2,
                  gap: 1,
                  borderRadius: 16,
                  fontSize: 12,
                  backgroundColor: `${getChipVariantForCounterOfferStatus(value)}.50`,
                  color: `${getChipVariantForCounterOfferStatus(value)}.700`,
                  fontFamily: "inherit",
                  cursor: "default",
                }}
                data-tip={row.original.statusMessage && t(row.original.statusMessage)}
              >
                {t(startCase(value))}
                {row.original.statusMessage && (
                  <IconInfo
                    noSvgPositioning
                    color="primary.700"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      svg: {
                        width: 16,
                        height: 16,
                        path: {
                          fill: "primary.500",
                        },
                      },
                      width: "fit-content",
                      height: "fit-content",
                    }}
                  />
                )}
              </Button>
            </Flex>
          );
        },
      },
      {
        Header: t("Action"),
        id: "action",
        Cell: ({ row }: { row: Row<Offer> }) => {
          return (
            <>
              {canTakeOfferAction(row.original.status, row.original.fromAccountId, OfferActions.WITHDRAW) && (
                <TableActionsButtons
                  actions={[
                    <Button
                      key={row.id + 1}
                      variant="pill.compactSecondary"
                      sx={{ py: 0, px: 3, height: "30px", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
                      onClick={() => initReviewOffer(OfferAction.Withdraw, row.original.offerNumber)}
                    >
                      {t("Withdraw")}
                    </Button>,
                  ]}
                />
              )}
              {canTakeOfferAction(row.original.status, row.original.fromAccountId, OfferActions.PROCESS) && (
                <TableActionsButtons
                  actions={[
                    <Button
                      key={row.id + 2}
                      variant="pill.compactSecondary"
                      sx={{ py: 0, px: 3, height: "30px", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
                      onClick={() => initReviewOffer(OfferAction.Accept, row.original.offerNumber)}
                    >
                      {t("Accept")}
                    </Button>,
                    <Button
                      key={row.id + 3}
                      variant="pill.compactSecondary"
                      sx={{ py: 0, px: 3, height: "30px", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
                      onClick={() => initReviewOffer(OfferAction.Reject, row.original.offerNumber)}
                    >
                      {t("Reject")}
                    </Button>,
                    <Button
                      key={row.id + 4}
                      variant="pill.compactSecondary"
                      sx={{ py: 0, px: 3, height: "30px", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
                      onClick={() => initCreateCounterOffer(row.original)}
                    >
                      {t("Counter")}
                    </Button>,
                  ]}
                />
              )}
            </>
          );
        },
      },
    ];
  }, [t, canTakeOfferAction, initReviewOffer, initCreateCounterOffer]);

  return (
    <>
      <Table
        dataSource={offers}
        loading={loading}
        columns={columns}
        translation={getTableTranslation(t)}
        pagination={false}
        testId="CounterOffers"
      />
      <CounterReviewModal
        visible={showOfferReviewModal}
        title={reviewOfferModalTitle}
        setShowOfferReviewModal={setShowOfferReviewModal}
        onReviewConfirm={onReviewConfirm}
        loading={reviewOfferLoading}
        subtitle={reviewOfferModalDescription}
      />
    </>
  );
};
