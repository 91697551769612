import { Button, Chip, Flex, IconArrow, IconChevronDown, Table, Text } from "@powerledger/ui-component-lib";
import currency from "currency.js";
import { startCase } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Column, Row } from "react-table";

import { EligibilityValueFormatter } from "@/app/components/eligibility-value-formatter";
import { HeaderText } from "@/app/components/page-header/header-text";
import { RefreshIconButton } from "@/app/components/refresh-icon-button";
import { SeparatorLayout } from "@/app/components/separator-layout";
import { TableValueWrapper } from "@/app/components/table-value-wrapper";
import { UserMessage } from "@/app/components/user-message/user-message";
import { AppDateFormats, formatDate } from "@/app/lib/format-date";
import { getTableTranslation } from "@/app/lib/get-translations-for-components";
import { OfferGroup, OfferGroupStatus } from "@/app/types/generated/graphql";

import { CounterOffers } from "../counter-offers";
import { AddDataProps, CounterOfferGroupsViewProps } from "./counter-offer-groups.types";

export const CounterOfferGroupsView = ({
  tableData,
  counteredRECS,
  goBack,
  loading,
  reloadOfferGroups,
}: CounterOfferGroupsViewProps) => {
  const { t } = useTranslation();
  const counterListTableColumns = useMemo<Column<OfferGroup>[]>(() => {
    return [
      {
        id: "expandable",
        Cell: ({ row }: { row: Row<OfferGroup> }) => {
          const { isExpanded } = row;
          return (
            <Button
              sx={{
                background: "transparent",
                "&:enabled:hover, &:enabled:focus, &:enabled:active": {
                  background: "transparent",
                },
              }}
              aria-label={`expand row ${row.index + 1}`}
              onClick={(e) => {
                e.stopPropagation();
                row.toggleRowExpanded();
              }}
            >
              <IconChevronDown
                width={6}
                height={6}
                color="textDark"
                style={{
                  transform: isExpanded ? "rotate(180deg)" : "",
                }}
              />
            </Button>
          );
        },
      },
      {
        Header: "Offer Group Number",
        accessor: "offerGroupNumber",
        disableSortBy: true,
        Cell: ({ value }: { value: string }) => <TableValueWrapper value={value} containerSx={{ minWidth: 80 }} />,
      },
      {
        Header: "Created On",
        accessor: "createdOn",
        disableSortBy: true,
        Cell: ({ value }: { value: string }) => (
          <TableValueWrapper
            value={formatDate(value, { formatStyle: AppDateFormats.AbbreviatedMonthFormatWithTime })}
            title="Received On"
            containerSx={{ minWidth: 80 }}
          />
        ),
      },
      {
        Header: t("Status"),
        accessor: "status",
        disableSortBy: true,
        Cell: ({ value }: { value: OfferGroupStatus }) => {
          const getVariant = {
            [OfferGroupStatus.Accepted]: "chips.primary",
            [OfferGroupStatus.Executed]: "chips.primary",
            [OfferGroupStatus.ExecutionFailed]: "chips.error",
            [OfferGroupStatus.Failed]: "chips.error",
            [OfferGroupStatus.InProgress]: "chips.info",
            [OfferGroupStatus.Rejected]: "chips.error",
            [OfferGroupStatus.Withdrawn]: "chips.info",
          };
          return (
            <Chip
              sx={{
                variant: getVariant[value],
              }}
              label={t(startCase(value))}
            />
          );
        },
      },
    ];
  }, [t]);
  return (
    <Flex as="main" sx={{ m: 4, flexDirection: "column", gap: 4 }}>
      <Button
        variant="text"
        sx={{ color: "accentLight", textDecoration: "underline", textUnderlineOffset: 2, alignSelf: "flex-start" }}
        onClick={goBack}
      >
        <IconArrow size={4} color="accentLight" />
        {t("Back")}
      </Button>
      <Flex sx={{ flexDirection: "inherit", gap: 2 }}>
        <HeaderText title="Counter Offer Lists" sx={{ width: "fit-content" }} />
        <UserMessage message="Here is the list of counter offers received for the following RECS. By default, the most recent offers are displayed first." />
      </Flex>
      {counteredRECS ? (
        <>
          <SeparatorLayout
            contentStyle={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: "14%",
            }}
            data={{
              Vintage: counteredRECS.vintages,
              Eligibility: (
                <EligibilityValueFormatter
                  certificationsAndEligibilities={counteredRECS.certificationsAndEligibilities}
                  position={counteredRECS.position}
                />
              ),
              Project: counteredRECS.projects,
              "Fuel Sources": counteredRECS.fuelSources,
              "Ask Volume": counteredRECS.currentVolume,
              "Ask Price": currency(counteredRECS.price.amount || 0, {
                separator: ",",
                fromCents: true,
              }).format(),
            }}
          />
          <Flex sx={{ justifyContent: "flex-end", marginY: -3 }}>
            <RefreshIconButton fetchData={reloadOfferGroups} loading={loading} />
          </Flex>
          <Table
            loading={loading}
            columns={counterListTableColumns}
            dataSource={tableData}
            translation={getTableTranslation(t)}
            renderExpandedComponent={(data) => {
              const expandedData = data as AddDataProps["expandedData"];
              return <CounterOffers offerGroupNumber={expandedData.offerGroupNumber} />;
            }}
            containerSx={{
              tbody: {
                "&:nth-of-type(n+2):before": {
                  borderColor: "shadow",
                  content: "''",
                  borderTopWidth: "0.5px",
                  borderTopStyle: "solid",
                  borderBottom: 0,
                  width: "100%",
                  position: "absolute",
                  top: 0,
                },
              },
              td: {
                borderTopLeftRadius: "0 !important",
                borderTopRightRadius: "0 !important",
              },
            }}
            pagination={false}
          />
        </>
      ) : (
        <Text>{t("No any Selected Recs")}</Text>
      )}
    </Flex>
  );
};
