import { Dispatch, SetStateAction } from "react";

import { Offer, OfferAction, OfferStatus } from "@/app/types/generated/graphql";

export type CounterOffersProps = {
  offerGroupNumber: string;
};

export type CounterOffersViewProps = {
  offers: Offer[];
  loading: boolean;
  canTakeOfferAction: (offerStatus: OfferStatus, offerFromId: string, action: OfferActions) => boolean;
  showOfferReviewModal: boolean;
  initReviewOffer: (reviewAction: OfferAction, offerNumber: string) => void;
  setShowOfferReviewModal: Dispatch<SetStateAction<boolean>>;
  onReviewConfirm: () => Promise<void>;
  reviewOfferModalTitle: string;
  reviewOfferLoading: boolean;
  reviewOfferModalDescription: string;
  initCreateCounterOffer: (counteredOffer: Offer) => void;
};

export enum OfferActions {
  WITHDRAW,
  PROCESS,
}

export enum ReviewOfferModalDescription {
  WITHDRAW = "Please note that once withdrawn, this action cannot be undone, and the offer will no longer be available for the other party. Please confirm if you’d like to proceed.",
  ACCEPT = "Accepting this offer will finalize the deal. Please confirm to proceed.",
  REJECT = "Once rejected, the offer will no longer be available. Please confirm your decision to proceed.",
}

export enum ReviewActionNotifications {
  WITHDRAW = "The offer has been withdrawn.",
  ACCEPT = "You’ve successfully accepted the offer.",
  REJECT = "The offer has been rejected.",
}
