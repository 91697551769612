import { Box, Button, Flex, IconLoading, Modal, Paragraph, Text, Tooltip } from "@powerledger/ui-component-lib";
import currency from "currency.js";
import { Field, Formik, useFormikContext } from "formik";
import { isEqual } from "lodash";
import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { getTotalValue, getTransactionFee, ORDER_LOWER_LIMIT, ORDER_UPPER_LIMIT } from "@/app/lib/order-helpers";
import { OrderPosition, OrderType } from "@/app/types/generated/graphql";

import { AppCleaveTypes, CleaveInput } from "../cleave-input";
import { EligibilityValueFormatter } from "../eligibility-value-formatter";
import { SeparatorLayout } from "../separator-layout";
import {
  CounterFormField,
  CounterSteps,
  CreateCounterOfferModalProps,
  FormValues,
  QuanityFieldProps,
} from "./create-counter-offer-modal.types";

const formFields = [
  {
    label: "Counter Volume",
    fieldName: CounterFormField.counterVolume,
    fieldType: AppCleaveTypes.Quantity,
    hasPrefix: false,
  },
  {
    label: "Counter Price",
    fieldName: CounterFormField.counterPrice,
    fieldType: AppCleaveTypes.Amount,
    hasPrefix: true,
  },
];

const QuanityField: FC<QuanityFieldProps> = ({ currentStep, field, currencySymbol, orderType }) => {
  const { t } = useTranslation();
  const { errors } = useFormikContext<FormValues>();
  const { fieldName, label, hasPrefix, fieldType } = field;
  const isOrderTypeAON = fieldName === CounterFormField.counterVolume && orderType === OrderType.Aon;
  const isDisabled = currentStep === CounterSteps.COUNTER_SUBMIT || isOrderTypeAON;

  return (
    <Flex
      sx={{
        width: "25%",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <Text
        sx={{
          display: "flex",
          fontSize: 2,
          fontWeight: "bold",
          color: "textDark",
        }}
      >
        {t(`{{label}}`, { label })}
        {currentStep === CounterSteps.COUNTER_FORM && isOrderTypeAON && (
          <Tooltip
            id={fieldName}
            content={
              <Box
                sx={{
                  fontSize: 0,
                  fontWeight: 500,
                }}
              >
                <Trans>
                  Counter volume cannot be changed for{" "}
                  <Text
                    sx={{
                      color: "primary.600",
                      fontWeight: 600,
                    }}
                  >
                    AON (All or None)
                  </Text>{" "}
                  order type.
                </Trans>
              </Box>
            }
            sx={{
              display: "flex",
              width: 20,
              height: 20,
            }}
          />
        )}
      </Text>
      <Field
        name={fieldName}
        component={CleaveInput}
        prefix={hasPrefix && currencySymbol}
        type={fieldType}
        disabled={isDisabled}
        sx={{
          input: {
            fontSize: 2,
          },
        }}
      />
      <Paragraph
        sx={{
          fontSize: 0,
          color: "error.500",
        }}
      >
        {errors?.[fieldName as keyof FormValues]}
      </Paragraph>
    </Flex>
  );
};

export const CreateCounterOfferModalView = ({
  visible,
  closeModal,
  counteredTableData,
  currentStep,
  modalSubTitle,
  handleStepChange,
  currencySymbol,
  onCounterConfirm,
  fee,
  counteredVolume,
}: CreateCounterOfferModalProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const initialValues = {
    counterVolume: counteredVolume ?? counteredTableData.currentVolume,
    counterPrice: currency(counteredTableData.price.amount || 0, { fromCents: true }).value,
  };
  return (
    <Modal visible={visible} onCancel={closeModal} title="Counter Offer" subtitle={modalSubTitle}>
      <Flex
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 4,
        }}
      >
        {currentStep === CounterSteps.COUNTER_SUCCESS ? (
          <Flex
            sx={{
              justifyContent: "flex-end",
              gap: 2,
            }}
          >
            <Button variant="error" onClick={closeModal}>
              {t("Cancel")}
            </Button>
            <Button
              onClick={() => {
                navigate("/counter/my-offers");
                closeModal();
              }}
            >
              {t("View Offer")}
            </Button>
          </Flex>
        ) : (
          <>
            <SeparatorLayout
              contentStyle={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                width: "14%",
              }}
              data={{
                Vintage: counteredTableData.vintages,
                Eligibility: (
                  <EligibilityValueFormatter
                    certificationsAndEligibilities={counteredTableData.certificationsAndEligibilities}
                    position={counteredTableData.position}
                  />
                ),
                Project: counteredTableData.projects,
                "Fuel Sources": counteredTableData.fuelSources,
                "Ask Volume": counteredVolume ?? counteredTableData.currentVolume,
                "Ask Price": currency(counteredTableData.price.amount || 0, {
                  separator: ",",
                  fromCents: true,
                }).format(),
              }}
            />
            <Formik
              validateOnMount
              initialValues={initialValues}
              validationSchema={Yup.object().shape({
                counterVolume: Yup.number()
                  .test(
                    "required-positive-min",
                    t("Enter valid volume"),
                    (value) => value !== undefined && value > 0 && value >= 1,
                  )
                  .max(counteredTableData.currentVolume || 0, t("Counter's volume cannot exceed the total ask volume")),
                counterPrice: Yup.number()
                  .test(
                    "required-positive-min",
                    t("Enter valid amount"),
                    (value) => value !== undefined && value > 0 && value >= 1,
                  )
                  .when("counterVolume", (counterVolume) => {
                    return Yup.number().test(
                      "required-positive-min",
                      t("Total Counter Price must be between $50 and $200k"),
                      function (value = 1) {
                        const totalPrice = getTotalValue(value, counterVolume, OrderPosition.Bid, fee).value;
                        return totalPrice >= ORDER_LOWER_LIMIT && totalPrice <= ORDER_UPPER_LIMIT;
                      },
                    );
                  }),
              })}
              onSubmit={async (values) => onCounterConfirm(values)}
            >
              {({ handleSubmit, isValid, isSubmitting, values, errors }) => {
                const isUnChanged = isEqual(initialValues, {
                  counterVolume: Number(values.counterVolume),
                  counterPrice: Number(values.counterPrice),
                });
                return (
                  <>
                    <Flex
                      sx={{
                        gap: 3,
                      }}
                    >
                      {formFields.map((field, index) => (
                        <QuanityField
                          currentStep={currentStep}
                          field={field}
                          key={`${field.fieldName}-${index}`}
                          currencySymbol={currencySymbol}
                          orderType={counteredTableData.type}
                        />
                      ))}
                      <Flex
                        sx={{
                          flexDirection: "column",
                          gap: "13px",
                          fontSize: 2,
                        }}
                      >
                        <Text
                          sx={{
                            fontWeight: "bold",
                            color: "textDark",
                          }}
                        >
                          {t("Transaction Fee ({{value}}%)", {
                            value: fee * 100,
                          })}
                        </Text>
                        <Text
                          sx={{
                            color: errors.counterPrice ? "error.500" : "text",
                          }}
                        >
                          {getTransactionFee(values.counterPrice, values.counterVolume, fee).format()}
                        </Text>
                      </Flex>
                      <Flex
                        sx={{
                          flexDirection: "column",
                          gap: "13px",
                          fontSize: 2,
                        }}
                      >
                        <Text
                          sx={{
                            fontWeight: "bold",
                            color: "textDark",
                          }}
                        >
                          {t("Total Counter Price")}
                        </Text>
                        <Text
                          sx={{
                            color: errors.counterPrice ? "error.500" : "text",
                          }}
                        >
                          {getTotalValue(values.counterPrice, values.counterVolume, OrderPosition.Bid, fee).format()}
                        </Text>
                      </Flex>
                    </Flex>
                    <Flex
                      sx={{
                        justifyContent: "flex-end",
                        gap: 2,
                      }}
                    >
                      {currentStep === CounterSteps.COUNTER_FORM ? (
                        <>
                          <Button variant="error" onClick={closeModal}>
                            {t("Cancel")}
                          </Button>
                          <Button
                            disabled={!isValid || isSubmitting || isUnChanged}
                            onClick={() => {
                              handleStepChange(CounterSteps.COUNTER_SUBMIT);
                            }}
                          >
                            {t("Next")}
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            variant="text"
                            disabled={isSubmitting}
                            sx={{ color: "accentLight", textDecoration: "underline", textUnderlineOffset: 2 }}
                            onClick={() => handleStepChange && handleStepChange(CounterSteps.COUNTER_FORM)}
                          >
                            {t("Go back")}
                          </Button>
                          <Button disabled={!isValid || isSubmitting} type="submit" onClick={() => handleSubmit()}>
                            {t("Confirm")}
                          </Button>
                        </>
                      )}
                      {isSubmitting && <IconLoading size="small" />}
                    </Flex>
                  </>
                );
              }}
            </Formik>
          </>
        )}
      </Flex>
    </Modal>
  );
};
