import { Button, Flex, IconLoading, Modal } from "@powerledger/ui-component-lib";
import { useTranslation } from "react-i18next";

import { CounterReviewModalProps } from "./counter-review-modal.types";

export const CounterReviewModal = ({
  visible,
  setShowOfferReviewModal,
  title,
  onReviewConfirm,
  loading,
  subtitle,
}: CounterReviewModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal
      visible={visible}
      onCancel={() => setShowOfferReviewModal(false)}
      maskClosable
      hideCloseButton
      sx={{ maxHeight: "inherit" }}
      title={t(title)}
      subtitle={t(subtitle)}
    >
      <Flex sx={{ justifyContent: "flex-end", mt: 4 }}>
        <Button
          variant="error"
          onClick={() => setShowOfferReviewModal(false)}
          sx={{ mr: 2, minWidth: 80 }}
          disabled={loading}
        >
          {t("Cancel")}
        </Button>
        <Button variant="primary" onClick={onReviewConfirm} disabled={loading}>
          {t("Proceed")}
        </Button>
        {loading && <IconLoading size="small" sx={{ ml: 2 }} />}
      </Flex>
    </Modal>
  );
};
