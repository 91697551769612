import { useTablePaginationManager } from "@powerledger/ui-component-lib";
import { subYears } from "date-fns";
import { useCallback, useMemo, useState } from "react";

import { defaultFilter, mapStackedFilterStateToFilterState } from "@/app/components/page-header/filter";
import { useRecOptions } from "@/app/hooks";
import { useAllFeatureToggleValues } from "@/app/hooks/use-feature-toggle/use-all-feature-toggle-values";
import { getDatePart } from "@/app/lib/date-helpers";

import { FilterState, StackedFilterState } from "../../components/page-header/filter/filter.types";
import { formatAttributes, ReturnTypeFormatAttributes } from "../../lib/format-attributes";
import {
  AssetType,
  OrderBookSortOrderInput,
  OrderPosition,
  RecOrderAttributes,
  useMarketOrdersV2Query,
  useMarketOrdersWithoutCounterQuery,
} from "../../types/generated/graphql";
import { MarketTableData, MarketViewProps } from "./market.types";

export const useMarket = (): MarketViewProps => {
  const [typeFilter, setTypeFilter] = useState<OrderPosition | null>(null);
  const [selectedFilters, setSelectedFilters] = useState<FilterState>(defaultFilter);
  const [showAllAttributes, setShowAllAttributes] = useState(false);
  const { toggles } = useAllFeatureToggleValues();

  const { pageInfo, offset, fetchData, sort, resetPage, setTotalItems } = useTablePaginationManager<
    MarketTableData,
    OrderBookSortOrderInput[]
  >({});

  const useMarketQuery = toggles.EnableCounterOffer ? useMarketOrdersV2Query : useMarketOrdersWithoutCounterQuery;

  const { data, loading, previousData, refetch } = useMarketQuery({
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        offSetPaginationInfo: {
          offset,
          limit: pageInfo.pageSize,
        },
        sortOrderInputs: sort,
        where: {
          recAttributes: {
            eligibilities: selectedFilters.eligibilities,
            certifications: selectedFilters.certifications,
            locations: selectedFilters.locations,
            fuelSources: selectedFilters.fuelSources,
            vintages: selectedFilters.vintages,
            projects: selectedFilters.projects,
            commencementDate: selectedFilters.cod?.length
              ? getDatePart(subYears(new Date(), +selectedFilters.cod?.[0]))
              : undefined,
          },
          assetType: AssetType.Rec,
          position: typeFilter,
        },
      },
    },
    onCompleted(data) {
      setTotalItems(data?.marketOrdersV2?.offsetInfo?.total ?? 0);
    },
  });

  const currentData = useMemo(() => data ?? previousData, [data, previousData]);

  const { recOptions, accountData, loading: recOptionsLoading } = useRecOptions();

  const handleChangeTypeFilter = useCallback(
    (position: OrderPosition | null) => {
      setTypeFilter(position);
      resetPage();
    },
    [resetPage],
  );

  const tableData = useMemo(() => {
    const marketTableData: MarketTableData[] = [];

    if (!currentData?.marketOrdersV2?.orders) return marketTableData;
    for (const order of currentData.marketOrdersV2.orders) {
      const attributes = order.attributes as RecOrderAttributes;
      const formattedAttributes = recOptions
        ? formatAttributes({
            attributes,
            filteredEligibility: selectedFilters.eligibilities[0],
            position: order.position,
            options: recOptions,
          })
        : ({} as ReturnTypeFormatAttributes);

      marketTableData.push({
        ...order,
        ...formattedAttributes,
        attributes,
      });
    }
    return marketTableData;
  }, [currentData?.marketOrdersV2?.orders, recOptions, selectedFilters.eligibilities]);

  const applyFilters = useCallback(
    (editingFilters: StackedFilterState) => {
      setSelectedFilters(mapStackedFilterStateToFilterState(editingFilters));
      resetPage();
    },
    [resetPage, setSelectedFilters],
  );

  const hideBuyButton = useMemo(() => typeFilter === OrderPosition.Bid, [typeFilter]);
  const hideSellButton = useMemo(() => typeFilter === OrderPosition.Ask, [typeFilter]);

  const assetCounts = useMemo(
    () => ({
      bid: currentData?.marketOrdersV2?.data?.totalBids ?? 0,
      ask: currentData?.marketOrdersV2?.data?.totalAsks ?? 0,
    }),
    [currentData?.marketOrdersV2?.data],
  );

  const reloadMarketOrders = useCallback(() => {
    refetch();
  }, [refetch]);

  return {
    typeFilter,
    handleChangeTypeFilter,
    reloadMarketOrders,
    fetchData,
    pageInfo,
    tableData,
    selectedFilters,
    loading: loading || recOptionsLoading,
    applyFilters,
    hideSellButton,
    assetCounts,
    hideBuyButton,
    recOptions,
    showAllAttributes,
    setShowAllAttributes,
    accountData,
  };
};
