import { Box, Button } from "@powerledger/ui-component-lib";

import { BellSVG } from "@/assets/icons";

import { NotificationBadgeViewProps } from "./notification-badge.types";
import { NotificationView } from "./notifications";

export const NotificationBadgeView: React.FC<NotificationBadgeViewProps> = ({
  showMenu,
  setShowMenu,
  buttonRef,
  menuRef,
  unreadMessageCount,
  ...logic
}) => {
  const displayCount = unreadMessageCount > 9 ? "9+" : unreadMessageCount;
  return (
    <>
      <Button
        onClick={() => setShowMenu((prev) => !prev)}
        aria-label="Notification Icon"
        sx={{
          display: "flex",
          bg: "transparent",
          p: 0,
          height: "35px",
          width: "35px",
          overflow: "unset",
          svg: {
            path: {
              stroke: "primaryHighlight",
            },
          },
          position: "relative",
          alignItems: "center",
          "&:enabled:hover, &:enabled:focus": {
            svg: {
              path: {
                stroke: "white",
              },
            },
          },
          ...(showMenu
            ? {
                bg: "activeLinkBackground",
                svg: {
                  path: {
                    stroke: "white",
                  },
                },
              }
            : {}),
        }}
        ref={buttonRef}
      >
        <BellSVG />
        {!!displayCount && (
          <Box
            sx={{
              textAlign: "center",
              top: -1,
              size: 3,
              fontSize: unreadMessageCount > 9 ? "9px" : 0,
              paddingTop: unreadMessageCount > 9 ? "1.5px" : 0,
              right: 0,
              lineHeight: "20px",
              width: "20px",
              height: "20px",
              borderRadius: "100%",
              position: "absolute",
              bg: "secondary",
              color: "primary.800",
            }}
          >
            {displayCount}
          </Box>
        )}
      </Button>
      {showMenu && (
        <Box
          ref={menuRef}
          sx={{
            position: "absolute",
            bg: "plain",
            top: 5,
            right: "20px",
            borderRadius: 4,
            overflowY: "auto",
            maxHeight: "600px",
            width: "500px",
            boxShadow: "0 0 10px var(--theme-ui-colors-shadow)",
            overscrollBehaviorY: "contain",
          }}
        >
          <NotificationView {...logic} />
        </Box>
      )}
    </>
  );
};
