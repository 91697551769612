import { IconInfo } from "@powerledger/ui-component-lib";
import { Flex, Text, ThemeUIStyleObject } from "theme-ui";

export type UserMessageProps = {
  sx?: ThemeUIStyleObject;
  message: string;
};

export const UserMessage = ({ sx, message }: UserMessageProps) => {
  return (
    <Flex
      sx={{
        background: "warning.50",
        padding: 2,
        borderRadius: 4,
        alignItems: "center",
        gap: 1,
        ...sx,
      }}
    >
      <IconInfo
        sx={{
          width: "20px",
          height: "20px",
        }}
        noSvgPositioning
        color={"primary.700"}
      />
      <Text
        sx={{
          fontSize: 1,
          fontWeight: 500,
          color: "textDark",
        }}
      >
        {message}
      </Text>
    </Flex>
  );
};
